<style scoped>
.forget-pass /deep/.el-form-item__label {
  line-height: 60px;
}

.login-sty-active {
  border: 1px solid #d9d9d9;
  height: 240px;
  padding: 70px 60px;
  display: flex;
  width: 600px !important;
  position: absolute;
  left: -160px;
  top: 37%;
}

.login-sty-active .demo-ruleForm {
  width: 400px !important;
}

.bind-wx {
  position: absolute;
  top: 20px;
  right: 400px;
  font-size: 16px;
  font-weight: bold;
}

.login-sty-active .success-tips {
  width: 160px;
  height: 160px;
  margin-right: 40px;
  background: #f1f1f1;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

}

.login-sty-active .success-tips img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.pass-login img {
  cursor: pointer;
  width: 40px;
  margin-top: 15px;
}

.wx-contain {
  width: 368px;
  height: 348px;
  border: 1px solid #d9d9d9;
  margin-left: 40px;
  position: relative;
  margin-top: 16px;

}

.wx-login {
  color: #86b8bc;
  cursor: pointer;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.wx-login-h1 {
  margin: 24px auto;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}

/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #fff;
  background-color: #86b8bc;
  border-color: #86b8bc;
}

/deep/.el-input input:-webkit-autofill {
  background: none;
  box-shadow: 0 0 0px 1000px #fff inset !important;
}

/deep/.el-input__inner {
  border: none;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  height: 44px;
  margin-top: 10px;
}


.pass-ruleForm /deep/.el-input__suffix {
  top: 15px !important;
  right: 10px !important;
  cursor: pointer;
}

.bac-img {
  width: 35%;
  height: 100vh;
  background: #4fa1a4;
  position: relative;
}

.bac-img img {
  width: 68%;
  margin-top: 10vh;
  left: 60px;
  border-radius: 10px;
}

.login-box {
  /* padding-top: 4vh; */
  /* padding-bottom: 4vh; */
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.login-right {
  float: right;
  position: relative;
  width: 460px;
  height: 100%;
  padding-top: 20vh;
  padding-bottom: 4vh;
  box-sizing: border-box;
  margin-right: calc(32.5% - 250px);
}

a {
  color: #4fa1a4;
}

/deep/.el-form-item__error {
  left: 15px;
}

.title-txt {
  font-size: 28px;
  margin-top: 30px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.font-line {
  font-size: 26px !important;
  color: rgb(202, 201, 201);
}

.cen-sty {
  /* width: 1000px; */
  margin: 0 auto;
}

.logo-sty-img-big {
  width: 250px;
  /* height: 58px; */
}

.logo-sty-img-box {
  width: 250px;
  height: 58px;
  margin-left: 105px;
}

.login-sty {
  width: 400px;
  height: 200px;
  border-radius: 4px;
  margin-top: 15px;
  margin-left: 40px;
}

.foot-sty {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  text-align: center;
}

.left-title {
  color: #fff;
  font-size: 22px;
  text-align: center;
  padding: 0 11%;
  font-weight: 500;
  line-height: 40px;
  margin-top: 8vh;
}

.desc-text {
  font-size: 15px;
  color: #fff;
  padding: 0 16% 0 16%;
  text-align: left;
  line-height: 28px;
}

.label-tip {
  text-align: left;
  padding-left: 15px;
  line-height: 13px;
  color: rgb(156, 156, 156);
}

.el-button+.el-button {
  margin-left: 0;
}

/deep/.el-input__inner::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 适配火狐 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner::-moz-placeholder {
  /* Mozilla Firefox 19+ 适配火狐 */
  color: rgb(156, 156, 156);
}

/deep/.el-input__inner:-ms-input-placeholder {
  /* Internet Explorer 10+  适配ie*/
  color: rgb(156, 156, 156);
}

/deep/.el-dialog {
  margin-left: calc(67.5% - 210px);
  border-radius: 8px;
}

.forget-sty {
  font-size: 12px;
  color: #b2b2b2;
  cursor: pointer;
}

.hqyzm {
  border-left: 1px solid #b2b2b2;
  width: 100px;
  height: 22px;
  font-size: 14px;
  line-height: 18px;
  color: #4fa1a4;
  padding-left: 10px;
  position: absolute;
  z-index: 5;
  right: 5px;
  margin-top: -30px;
  cursor: pointer;
  text-align: center;
}

.eye-k-sty {
  position: absolute;
  z-index: 9;
  right: 10px;
  margin-top: 26px;
  cursor: pointer;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #9d9d9d;
  font-size: 13px;
}

/deep/ .el-checkbox__label {
  color: #9d9d9d;
  font-size: 13px;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #4fa1a4;
  border-color: #4fa1a4;
}

.xieyi {
  font-size: 13px;
  margin: 20px 0;
  text-align: left;
}

.more-contain {
  font-size: 12px;
  color: #B2B2B2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-contain .line {
  height: 1px;
  width: 150px;
  background: #D9D9D9;

}

.more-contain .txt {
  margin: 0 10px;
}

.pass-ruleForm /deep/ .el-form-item {
  margin-bottom: 15px;
}
</style>
<template>
  <div v-if="!isBindWx" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="cen-sty">
      <div class="login-box">
        <div class="bac-img fl">
          <img src="../assets/images/left-pic.png" alt="" />
          <p class="left-title">运营人员的高效处理平台</p>
          <!-- <p class="left-title mt20">提供信息化产品与运营服务</p> -->
          <div class="desc-text">
            <p>
              帮助运营人员做什么？<br />-帮助运营人员辅助药企运营，<br />-支撑运营人员进行企业内部的人事管理、流程管理、报表管理、数据管理等日常工作。
            </p>
          </div>
        </div>
        <div class="login-right">
          <div class="logo-sty-img-box">
            <img class="logo-sty-img-big" src="../assets/images/logo-fbt.png" />
          </div>
          <div class="title-txt">
            <span class="fontC-4fa1a4">FBT运营平台</span> <span class="font-line">|</span>
            <span class="fontC-4fa1a4">登录</span>
          </div>

          <div class="fl wx-contain" v-if="isWxLogin">
            <div class="wx-login-h1">微信扫码登录</div>
            <div id="login_container">

            </div>
            <div class="wx-login" @click="isWxLogin = false">账号登录</div>
          </div>
          <div :class="!wxCode && !isBindWx ? 'login-sty fl' : 'login-sty-active login-sty fl'" v-else>
            <div class="bind-wx" v-if="wxCode">微信绑定账号</div>
            <div class="success-tips" v-if="wxCode">
              <img src="../assets/images/icon_wx_succ.png" />验证成功
            </div>
            <el-form :model="ruleForm" ref="ruleForm" label-width="0" class="demo-ruleForm  pass-ruleForm">
              <el-form-item label="" prop="logonUser">
                <!-- <p class="label-tip" v-if="ruleForm.logonUser !== ''">登录账号</p> -->
                <el-input v-model.trim="ruleForm.logonUser" class="w100f" placeholder="输入账号">
                  <i slot="suffix" v-if="ruleForm.logonUser" @click="ruleForm.logonUser = ''; ruleForm.pwd = ''">
                    <img style="width:15px;" src="../assets/ic_clear.png" alt="" srcset="">
                  </i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="pwd">
                <!-- <p class="label-tip" v-if="ruleForm.pwd !== ''">密码</p> -->
                <el-input :type="passw" v-model.trim="ruleForm.pwd" class="w100f" placeholder="输入密码">
                  <i slot="suffix" v-if="passw == 'password' && ruleForm.pwd" @click="passw = 'text'">
                    <img style="width:15px;" src="../assets/close_eye.png" alt="" srcset="">
                  </i>
                  <i slot="suffix" v-if="passw == 'text' && ruleForm.pwd" @click="passw = 'password'">
                    <img style="width:15px;" src="../assets/open_eye.png" alt="" srcset="">
                  </i>
                </el-input>
              </el-form-item>
              <div class="xieyi">
                <el-checkbox v-model="ruleForm.isAgree">我已阅读并同意<span style="color: #4fa1a4"
                    @click.stop.prevent="newOpen(0)">《隐私声明》</span>及<span style="color: #4fa1a4"
                    @click.stop.prevent="newOpen(1)">《用户协议》</span></el-checkbox>
              </div>
              <el-form-item>
                <el-button size="medium" :type="buttonType == 0 ? 'no' : 'info'"
                  style="width: 100%; height: 45px; border-radius: 0px" @click="submitForm('ruleForm')">登录</el-button>
                <div class="forget-sty" v-if="!wxCode && !isBindWx" @click="forgetPwd">忘记密码</div>
              </el-form-item>
              <div v-if="!wxCode && !isBindWx" class="more-contain">
                <div class="line"></div>
                <div class="txt">更多登录方式</div>
                <div class="line"></div>
              </div>
              <div v-if="!wxCode && !isBindWx" class="pass-login" @click="openCode">
                <img src="../assets/images/icon_wx.png" alt="" srcset="">
              </div>
            </el-form>
          </div>
          <div class="foot-sty">
            <span>COPYRIGHT © 2016-2024</span>
            <a href="https://beian.miit.gov.cn" target="_blank"> 京ICP备2024044205号 </a> 北京传世博润科技有限公司
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="找回密码" :visible.sync="dialogFormVisible" width="460px">
      <el-form :model="ruleForm1" ref="ruleForm1" label-width="100px" class="forget-pass demo-ruleForm">
        <el-form-item label="手机号:" prop="mobileNo">
          <el-input v-model="ruleForm1.mobileNo" autocomplete="off" class="w100f" placeholder="请输入绑定的手机号"></el-input>
          <div class="hqyzm" @click="runcode()">{{ btntext }}</div>
        </el-form-item>
        <el-form-item label="验证码:" prop="smsCode">
          <el-input v-model="ruleForm1.smsCode" autocomplete="off" class="w100f" maxlength="20" placeholder="请输入验证码">
          </el-input>
        </el-form-item>
        <el-form-item label="新密码:" prop="pwd">
          <el-input v-model="ruleForm1.pwd" :type="password" autocomplete="off" class="w100f" placeholder="请输入6-16位字符">
          </el-input>
          <img class="eye-k-sty" width="21" src="../assets/images/eye-k.png" v-if="password == 'password'"
            @click="shPwd('text')" />
          <img class="eye-k-sty" width="21" src="../assets/images/eye-g.png" v-if="password == 'text'"
            @click="shPwd('password')" />
        </el-form-item>
        <el-form-item label="确认密码:" prop="pwd2">
          <el-input v-model="ruleForm1.pwd2" :type="password1" autocomplete="off" class="w100f" maxlength="20"
            placeholder="请再次输入新密码"></el-input>
          <img class="eye-k-sty" width="21" src="../assets/images/eye-k.png" v-if="password1 == 'password'"
            @click="shPwd1('text')" />
          <img class="eye-k-sty" width="21" src="../assets/images/eye-g.png" v-if="password1 == 'text'"
            @click="shPwd1('password')" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button plain size="medium" class="mr15" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="medium" type="info" @click="resetPwd()"
          v-if="ruleForm1.mobileNo != '' && ruleForm1.smsCode != '' && ruleForm1.pwd != '' && ruleForm1.pwd2 != ''">确定
        </el-button>
        <el-button size="medium" type="info" style="background: #c4c4c4; border: 1px solid #c4c4c4" v-else>确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import sha1 from "sha1";
export default {
  data() {
    //const ENV = location.href.indexOf('https://scm-operation.csyaoly.com') > -1?'pro': location.href.indexOf('https://scm-operation.cs4pl.com') > -1?'pre':'sit'
    return {
      unionId: null,
      wxCode: null,
      isBindWx: true,
      isWxLogin: false,
      radio1: '运营端',
      passw: "password",
      ruleForm: {
        logonUser: '',
        pwd: '',
        validateCode: 12345,
        platformGuid: '1e2409b7ef0b412c2bb84e38f8fe50ba',
        appSideGuid: '03cb364e04d894a44cdb94273abffa13',
        userStatusType: 1,
        isAgree: false,
      },
      fullscreenLoading: false,
      menuList: [],
      btntext: '获取验证码',
      second: 120,
      disabled: false,
      password: 'password',
      password1: 'password',
      dialogFormVisible: false,
      ruleForm1: {
        mobileNo: '',
        smsCode: '',
        pwd: '',
        pwd2: '',
      },
    };
  },
  computed: {
    buttonType() {
      if (this.ruleForm.logonUser !== '' && this.ruleForm.pwd !== '' && this.ruleForm.isAgree) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  created() {
    document.title = '登录';
    localStorage.clear();
    if (this.$route.query.code) {
      this.wxCode = this.$route.query.code;
      this.getUserWeChatInfo();
    } else {
      this.isBindWx = false;
    }
    if (window.location.search == '?1') {
      localStorage.setItem('login_state', '2');
    }
  },
  methods: {
    newOpen(type) {
      if (type == 1) {
        window.open(window.location.origin + '/userAgree')
      } else {
        window.open(window.location.origin + '/userPrivate')
      }
    },
    shPwd(flg) {
      this.password = flg;
    },
    shPwd1(flg) {
      this.password1 = flg;
    },
    forgetPwd() {
      let self = this;
      self.btntext = '获取验证码';
      clearInterval(self.clock);
      self.dialogFormVisible = true;
      self.ruleForm1.mobileNo = '';
      self.ruleForm1.smsCode = '';
      self.ruleForm1.pwd = '';
      self.ruleForm1.pwd2 = '';
    },
    runcode() {
      let self = this;
      let testTel = /^1[3456789]\d{9}$/;
      if (!self.ruleForm1.mobileNo || !testTel.test(self.ruleForm1.mobileNo)) {
        self.$message.error('请输入您的正确手机号码！');
        return false;
      }
      if (self.disabled) {
        return false;
      }
      self.api
        .get(
          `/${serverConfig.msCommonAdminPlatformAuthService}/user/data/get-msg-code?mobileNo=` +
          self.ruleForm1.mobileNo +
          '&terminalType=' +
          '1',
        )
        .then((res) => {
          if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
            self.$message.success('发送验证码成功');
            self.clock = setInterval(self.sendCode, 1000);
          } else {
            self.$message.error(res.data.msg);
          }
        });
    },
    sendCode() {
      let self = this;
      self.second--;
      self.disabled = true;
      self.btntext = self.second + '秒后可获取';
      if (self.second == 0) {
        clearInterval(self.clock);
        self.second = 120;
        self.btntext = '获取验证码';
        self.disabled = false;
      }
    },
    resetPwd() {
      let self = this;
      let testTel = /^1[345789]\d{9}$/;
      if (!self.ruleForm1.mobileNo || !testTel.test(self.ruleForm1.mobileNo)) {
        self.$message.error('请输入您的正确手机号码！');
        return;
      }
      if (self.ruleForm1.pwd == '') {
        self.$message.error('请输入密码！');
        return false;
      }
      if (self.ruleForm1.pwd.length < 6) {
        self.$message.error('密码长度至少6位！');
        return false;
      }
      if (self.ruleForm1.pwd !== self.ruleForm1.pwd2) {
        self.$message.error('两次密码请填写一样！');
        return false;
      }
      let query = {
        mobileNo: self.ruleForm1.mobileNo,
        pwd: self.$md5(self.ruleForm1.pwd),
        pwd2: self.$md5(self.ruleForm1.pwd2),
        smsCode: self.ruleForm1.smsCode,
      };
      self.api
        .put(`/${serverConfig.msCommonAdminPlatformAuthService}/user/data/resetPwd-byMsgCode`, query)
        .then((res) => {
          if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
            self.$message.success('密码修改成功，请重新登录');
            self.dialogFormVisible = false;
          } else {
            self.$message.error(res.data.msg);
          }
        });
    },
    // 排序
    getSort(ruleForm) {
      let arr = [];
      let sum = 0;
      let parame = '';
      for (let r in ruleForm) {
        sum++
        arr.push(r + ruleForm[r]);
      }
      arr = arr.sort();
      if (sum == arr.length) {
        arr.forEach((i) => {
          parame = parame + i;

        })
      }
      // console.log(parame);
      return parame;
    },
    submitForm(formName) {
      let self = this;
      let pwd = '';
      if (self.ruleForm.pwd) {
        pwd = self.$md5(self.ruleForm.pwd);
      }
      let ruleFormNew = {
        logonUser: self.ruleForm.logonUser,
        pwd: self.$md5(self.ruleForm.pwd),
        platformGuid: self.ruleForm.platformGuid,
        appSideGuid: self.ruleForm.appSideGuid,
        userStatusType: self.ruleForm.userStatusType
      };
      let par = self.getSort(ruleFormNew);
      let time = Date.parse(new Date());
      let parame = 'FBT@CsBR_943Rsve30fFFBPoi0o0o0' + par + 'FBT@CsBR_943Rsve30fFFBPoi0o0o0';
      let sign = sha1(parame).toUpperCase();
      if (!this.ruleForm.isAgree) return;
      self.$refs[formName].validate((valid) => {
        if (valid) {
          self.api
            .postSign(
              '/csbr-zuul/user/login?' +
              `logonUser=` +
              self.ruleForm.logonUser +
              `&platformGuid=` +
              self.ruleForm.platformGuid +
              `&pwd=` +
              pwd +
              `&appSideGuid=` +
              self.ruleForm.appSideGuid +
              `&userStatusType=` +
              self.ruleForm.userStatusType +
              `&validateCode=` +
              self.ruleForm.validateCode, { 'sign': sign, 'timeStamp': time / 1000 }
            )
            .then((res) => {
              if (res.data.code == serverConfig.REQUIRE_SUCCESS_CODE) {
                // window.sessionStorage.setItem('token',res.data.data.token);
                localStorage.setItem(
                  'wynToken',
                  JSON.stringify('670550ae1460a86607190cd98b36eea4dcf0069a7e9d1f8c3af08b4b6936e6ef'),
                );
                self.$store.commit('$_setStorage', res.data.data.token);
                self.bindWxUnionId(res.data.data.token);
                //self.getLastLogin();
              } else {
                self.$message.error(res.data.msg);
              }
            });
        } else {
          self.$message.error('登录失败！');
          return false;
        }
      });
    },

    openCode() {
      this.isWxLogin = true;
      this.$nextTick(() => {
        let obj = new WxLogin({
          self_redirect: false,
          id: "login_container",
          appid: "wx4961fdf18ce4f575",
          scope: "snsapi_login",
          state: "STATE",
          style: 'black',
          redirect_uri: encodeURIComponent(location.href.split("/#")[0]),
          href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE1NXB4OyBtYXJnaW46IDBweDtib3JkZXI6IG5vbmU7fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDMwMHB4O30KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KLmltcG93ZXJCb3ggLmljb24zOF9tc2cuc3VjY3tkaXNwbGF5Om5vbmU7fQ=="
        });
      })
    },
    //根据code获取token
    getUserWeChatInfo() {
      let self = this;
      let ruleFormNew = {
        code: self.$route.query.code,
        platformGuid: self.ruleForm.platformGuid,
        appSideGuid: self.ruleForm.appSideGuid,
        userStatusType: self.ruleForm.userStatusType,
      };
      let par = self.getSort(ruleFormNew);
      let time = Date.parse(new Date());
      let parame = 'FBT@CsBR_943Rsve30fFFBPoi0o0o0' + par + 'FBT@CsBR_943Rsve30fFFBPoi0o0o0';

      let sign = sha1(parame).toUpperCase();
      self.api.postSign(`/csbr-zuul/user/webchat-login?platformGuid=` + self.ruleForm.platformGuid + `&code=` + ruleFormNew.code + `&appSideGuid=` + self.ruleForm.appSideGuid + `&userStatusType=` + self.ruleForm.userStatusType + `&validateCode=12345`, { 'sign': sign, 'timeStamp': time / 1000 }).then(res => {
        if (res.data.code == '00000') {
          //绑定成功直接跳转
          this.isBindWx = true;
          self.toLogin(res.data.data.token);
        } else if (res.data.code == 'U0200') {
          //用户未绑定
          this.isBindWx = false;
          this.unionId = res.data.data.unionId;
        } else {
          this.isBindWx = false;
          //异常
          this.$message.error(res.data.msg);
          this.wxCode = null;
        }
      })
    },
    //绑定微信号
    bindWxUnionId(token) {
      if (this.unionId) {
        this.api.put('/ms-common-admin-platform-auth-service/user/data/bind-webchat-user?unionId=' + this.unionId,).then(res => {
          if (res.data.code == '00000') {
            this.$message.success('微信绑定成功');
            this.toLogin(token)
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.toLogin(token)
      }
    },
    toLogin(token) {
      let url = url;
      if (env === 'pro') {
        //生产
        url = 'https://scm-operation.csyaoly.com/Login?token=' + token;
        // url = 'https://scm-operation.cs4pl.com/Login?token=' + token;
      } else if (env === 'sit') {
        //测试
        url = 'https://scm-operation-test.csbr.cn/Login?token=' + token;
      } else {
        //开发
        url = 'http://localhost:8086/Login?token=' + token;;
      }
      location.replace(url);
    }
  },
};
</script>
